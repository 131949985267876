import React from 'react'
import styled from '@emotion/styled'

// components
import { Heading, Chip } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'

// types
import {
  PremiumCalculatorQuotePreviewFields,
  Cover as CoverData,
} from '../../../../../types/components/PremiumCalculator'

// styles
import styles from './cover.styles'

type CoverProps = {
  fields: PremiumCalculatorQuotePreviewFields
  cover: CoverData
  isExisting: boolean
}

const StyledHeading = styled(Heading)(styles.heading)
const Wrapper = styled('div')(styles.wrapper)
const DataList = styled('dl')(styles.dataList)

const Cover = ({ fields, cover, isExisting }: CoverProps) => {
  const {
    benefitName,
    premiumAmount,
    coverAmount,
    coverStyle,
    premiumStyle,
    benefitType,
    featuresList,
    waitingPeriod,
    coverPeriod,
    benefitCommencementDate = '',
    paymentFrequency,
    updatedPaymentFrequency,
    tpdDefinition,
    newCoverAmount,
    newPremiumAmount,
    newWaitingPeriod,
    newCoverPeriod,
    newFeaturesList,
    showBenefitName,
  } = cover

  const coverData = [
    {
      key: fields?.Instance?.value,
      val: fields.StartFrom.value.replace('##', benefitCommencementDate),
    },
    {
      key: fields?.CoverAmount?.value,
      val: isExisting ? coverAmount : newCoverAmount,
      chipText: newCoverAmount && coverAmount !== newCoverAmount ? fields.Edited.value : '',
      chipVariant: 'default',
    },
    {
      key: fields?.Definition?.fields?.Heading?.value,
      val: tpdDefinition,
    },
    {
      key: fields.Type?.fields?.Heading?.value,
      val: benefitType,
    },
    {
      key: fields?.Style?.value,
      val: coverStyle,
    },
    {
      key: fields?.WaitingPeriod?.value,
      val: isExisting
        ? waitingPeriod && `${waitingPeriod.value} ${waitingPeriod.unit}`
        : newWaitingPeriod && `${newWaitingPeriod.value} ${newWaitingPeriod.unit}`,
      chipText:
        newWaitingPeriod && newWaitingPeriod.value !== waitingPeriod?.value
          ? fields.Edited.value
          : '',
      chipVariant: 'default',
    },
    {
      key: fields?.BenefitPeriod?.value,
      val: isExisting
        ? coverPeriod && `${coverPeriod.value} ${coverPeriod.unit}`
        : newCoverPeriod && `${newCoverPeriod.value} ${newCoverPeriod.unit}`,
      chipText:
        newCoverPeriod && newCoverPeriod.value !== coverPeriod?.value ? fields.Edited.value : '',
      chipVariant: 'default',
    },
    {
      key: fields.Options?.fields?.Heading?.value,
      val: isExisting
        ? featuresList?.map(({ displayedFeatureName }) => displayedFeatureName) || []
        : newFeaturesList?.map(({ displayedFeatureName }) => displayedFeatureName) || [],
      chipText:
        featuresList && featuresList?.length > 0 && featuresList?.length !== newFeaturesList?.length
          ? fields.Edited.value
          : '',
      chipVariant: 'default',
    },
    {
      key: fields?.Structure?.value,
      val: premiumStyle,
    },
    {
      key: fields?.Premium?.value,
      val: isExisting
        ? `${premiumAmount} ${paymentFrequency?.label || ''}`
        : `${newPremiumAmount || ''} ${updatedPaymentFrequency?.label || ''}`,
      chipText: newPremiumAmount && premiumAmount !== newPremiumAmount ? fields.New.value : '',
      chipVariant: 'selected',
    },
  ]

  const renderCoverItem = (field: {
    key: string
    val: string | string[] | undefined
    chipText?: string
    chipVariant?: string
  }) => {
    const fieldVal = field.val
    if (fieldVal) {
      if (Array.isArray(fieldVal)) {
        return (
          <>
            <dt>{`${field.key}:`}</dt>
            <dd>
              {fieldVal.length > 0 ? (
                <ul>
                  {fieldVal.map((val: string, index: number) => (
                    <li>
                      <span>{val}</span>
                      {!isExisting && index === 0 && field.chipText && (
                        <Chip variant={field.chipVariant}>{field.chipText}</Chip>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <span>{fields?.None.value}</span>
                  {field.chipText && !isExisting && (
                    <Chip variant={field.chipVariant}>{field.chipText}</Chip>
                  )}
                </>
              )}
            </dd>
          </>
        )
      }
      return (
        <>
          <dt>{`${field.key}:`}</dt>
          <dd>
            <span>{fieldVal}</span>
            {field.chipText && !isExisting && (
              <Chip variant={field.chipVariant}>{field.chipText}</Chip>
            )}
          </dd>
        </>
      )
    }
    return null
  }

  return (
    <Wrapper>
      {showBenefitName && (
        <StyledHeading variant="h4" size="SMD">
          <Icon iconName={['far', 'umbrella-simple']} />
          {benefitName}
        </StyledHeading>
      )}
      <DataList>{coverData.map(fieldType => renderCoverItem(fieldType))}</DataList>
    </Wrapper>
  )
}

export default Cover
