import 'core-js/es7/array'
import { batch } from 'react-redux'
import pick from 'lodash/pick'
import camelCase from 'lodash/camelCase'
import {
  CUSTOMER_POLICY_SELF_LOADING,
  CUSTOMER_POLICY_SELF_FAILED,
  CUSTOMER_POLICY_SELF_SUCCESS,
  CUSTOMER_POLICY_SUPPORTING_DATA_LOADING,
  CUSTOMER_POLICY_SUPPORTING_DATA_FAILED,
  CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS,
  SETUP_CUSTOMER_POLICY_STATUS_MAP,
} from '../types/customerPolicyStatus'
import {
  CUSTOMER_SUMMARY_LOADING,
  CUSTOMER_SUMMARY_FAILED,
  CUSTOMER_SUMMARY_SUCCESS,
  UPDATE_CUSTOMER_POLICY,
  CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS,
} from '../types/customerPolicies'
import { UPDATE_RELATIONSHIP_FROM_POLICIES_SUMMARY } from '../types/customerRelationships'
import { handleRelationshipUpdate } from './customerRelationships'
import { getQueueLength } from '../../utils/policyUtils'
import {
  callExperienceAPIv2,
  responseCameBackAllGood,
  PORTAL_API_PATH,
} from '../../utils/customerPortalExperienceAPIUtils'
import { checkIfAllPoliciesLoaded } from '../../selectors/common'

const EXECUTION_GROUP_LIMIT = 2

const handlePolicyUpdate = policies => dispatch => {
  dispatch({
    type: CUSTOMER_POLICY_SELF_SUCCESS,
    payload: policies.map(item => ({
      policyNo: item.businessData.policy.bancsPolicyNo,
      ...item.businessData.policy,
    })),
  })
  policies.forEach(item => {
    dispatch(handleRelationshipUpdate(item))
  })
}

const handlePolicySupportingDataUpdate = policies => dispatch => {
  dispatch({
    type: CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS,
    payload: policies.map(policy => ({
      response: policy.businessData,
      type: policy.type,
      policyNo: policy.policyNo,
    })),
  })
}

export const fetchFutureOutstandingBalance =
  (bancsPolicyNo, effectiveDate) => async (dispatch, getState) => {
    const { customerPolicyStatus } = getState()
    const policy = customerPolicyStatus[bancsPolicyNo]
    dispatch({
      type: CUSTOMER_POLICY_SELF_LOADING,
      payload: [policy],
    })

    const path = `/${PORTAL_API_PATH}/policy/${bancsPolicyNo}?effectiveDate=${effectiveDate}`
    const item = await callExperienceAPIv2({ path })
    if (responseCameBackAllGood(item)) {
      dispatch({
        type: CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS,
        payload: [
          {
            ...item,
            policyNo: bancsPolicyNo,
          },
        ],
      })
    } else {
      dispatch({
        type: CUSTOMER_POLICY_SELF_FAILED,
        payload: [policy],
      })
    }
  }

export const fetchPolicy = (bancsPolicyNo, effectiveDate) => async (dispatch, getState) => {
  const { customerPolicyStatus } = getState()

  const policy = customerPolicyStatus[bancsPolicyNo]

  dispatch({
    type: CUSTOMER_POLICY_SELF_LOADING,
    payload: [policy],
  })

  const path = `/${PORTAL_API_PATH}/policy/${bancsPolicyNo}${
    effectiveDate ? `?effectiveDate=${effectiveDate}` : ''
  }`
  const item = await callExperienceAPIv2({ path })
  if (responseCameBackAllGood(item)) {
    dispatch(handlePolicyUpdate([item]))
  } else {
    dispatch({
      type: CUSTOMER_POLICY_SELF_FAILED,
      payload: [policy],
    })
  }
}

/**
 * Checks to see if we should grab additional data from ODS
 * Returns level2 if conditions are met and policyRoles includes IET
 * Returns level1 if conditions are met and policyRoles doesnt include IET
 * */
const fetchOds = data => {
  const isBusiness = data.partyType === 'ORG'
  const policyRoles = [...new Set(Object.values(data.policyRolesMap).flatMap(item => item.roles))]
  const isOwnerAndNotNulis = policyRoles.includes('OWR') && data.bancsCustomerNo !== 'NULIS'
  const isPayer = policyRoles.includes('PYR')
  const isIETandNotSuperannuation =
    policyRoles.includes('IET') && !['MLCSAF', 'MLCSF'].includes(data.bancsCustomerNo)
  const shouldFetchOds = isBusiness && (isOwnerAndNotNulis || isPayer || isIETandNotSuperannuation)
  if (shouldFetchOds) {
    return `?fetchOds=${policyRoles.includes('IET') ? 'level2' : 'level1'}`
  }
  return ''
}
export const fetchRelatedParty = bancsCustomerNo => async (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: 'CUSTOMER_RELATEDPARTY_SELF_LOADING',
    payload: bancsCustomerNo,
  })

  const path = `/${PORTAL_API_PATH}/policy/${bancsCustomerNo}/related-party${fetchOds(
    state.customerRelationships[bancsCustomerNo]
  )}`
  const item = await callExperienceAPIv2({ path })

  dispatch(
    responseCameBackAllGood(item)
      ? {
          type: 'CUSTOMER_RELATEDPARTY_SELF_SUCCESS',
          payload: item,
          odsConditionMet: checkIfAllPoliciesLoaded(state),
        }
      : {
          type: 'CUSTOMER_RELATEDPARTY_SELF_FAILED',
          payload: bancsCustomerNo,
          noRetry: true,
        }
  )
}

export const fetchPolicySupportingData =
  (bancsPolicyNo, types = ['payment-instrument', 'work-items', 'payment-history']) =>
  async dispatch => {
    dispatch({
      type: CUSTOMER_POLICY_SUPPORTING_DATA_LOADING,
      payload: types.map(type => ({
        policyNo: bancsPolicyNo,
        type: camelCase(type),
      })),
    })

    await Promise.all(
      types.map(async type => {
        const path = `/${PORTAL_API_PATH}/policy/${bancsPolicyNo}/${type}`
        const item = await callExperienceAPIv2({ path })
        return {
          type: camelCase(type),
          policyNo: bancsPolicyNo,
          businessData: responseCameBackAllGood(item) ? item.businessData : false,
        }
      })
    ).then(data => {
      const successes = data.filter(item => Boolean(item.businessData))
      const failures = data.filter(item => !item || !item.businessData)

      batch(() => {
        if (failures.length > 0) {
          dispatch({
            type: CUSTOMER_POLICY_SUPPORTING_DATA_FAILED,
            payload: failures.map(failure => ({
              type: failure.type,
              policyNo: failure.policyNo,
            })),
          })
        }
      })
      if (successes.length > 0) {
        dispatch(handlePolicySupportingDataUpdate(successes))
      }
    })
  }

export const fetchPolicySelfAndSupportingData = bancsPolicyNo => async dispatch => {
  await dispatch(fetchPolicy(bancsPolicyNo))
  await dispatch(fetchPolicySupportingData(bancsPolicyNo))
}

export const fetchAllPolicySupportingData =
  (types = ['paymentInstrument', 'workItems', 'paymentHistory']) =>
  async (dispatch, getState) => {
    let policyQueue = []
    do {
      const { customerPolicyStatus } = getState()

      // Build the queue bases on what we want to load and whats left to load.
      policyQueue = Object.values(customerPolicyStatus)
        .reduce((queue, { statuses, policyNo }) => {
          let statusObject = {
            policyNo,
          }
          if (types.length > 0) {
            types.forEach(type => {
              statusObject = {
                ...statusObject,
                [type]: statuses[type],
              }
            })
          } else {
            statusObject = {
              ...statusObject,
              paymentInstrument: statuses.paymentInstrument,
              paymentHistory: statuses.paymentHistory,
              workItems: statuses.workItems,
            }
          }
          return queue.concat(statusObject)
        }, [])
        .filter(
          ({ paymentInstrument = {}, paymentHistory = {}, workItems = {} }) =>
            paymentInstrument.status === 'UNLOADED' ||
            paymentHistory.status === 'UNLOADED' ||
            workItems.status === 'UNLOADED'
        )

      const policiesToGet = policyQueue.slice(0, EXECUTION_GROUP_LIMIT)
      let policies = []
      const flattenedDataArray = policiesToGet
        .flatMap(policy =>
          Object.keys(policy).map(item =>
            item === 'policyNo'
              ? null
              : {
                  policyNo: policy.policyNo,
                  data: policy[item],
                  type: item,
                }
          )
        )
        .filter(Boolean)
      dispatch({
        type: CUSTOMER_POLICY_SUPPORTING_DATA_LOADING,
        payload: policiesToGet.flatMap(policy =>
          types.map(type => ({
            type,
            policyNo: policy.policyNo,
          }))
        ),
      })

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        flattenedDataArray.map(({ data: { url } }) => callExperienceAPIv2({ path: url }))
      ).then(data => {
        const formattedData = data.map((item, index) => ({
          type: flattenedDataArray[index].type,
          policyNo: flattenedDataArray[index].policyNo,
          businessData: responseCameBackAllGood(item) ? item.businessData : false,
        }))
        policies = policies.concat(...formattedData)
      })
      batch(() => {
        const unsuccessfulResponses = policies.filter(item => !item.businessData)
        if (unsuccessfulResponses.length > 0) {
          dispatch({
            type: CUSTOMER_POLICY_SUPPORTING_DATA_FAILED,
            payload: unsuccessfulResponses.map(policy => ({
              type: policy.type,
              policyNo: policy.policyNo,
            })),
          })
        }
        const successfulPolicies = policies.filter(item => item.businessData)
        if (successfulPolicies.length > 0) {
          dispatch(handlePolicySupportingDataUpdate(successfulPolicies))
        }
      })
    } while (getQueueLength(policyQueue) > 0)
  }

export const fetchAllPolicies = () => async (dispatch, getState) => {
  let policyQueue = []
  do {
    const { customerPolicyStatus } = getState()
    policyQueue = Object.values(customerPolicyStatus)
      .map(({ statuses, policyNo }) => ({
        self: statuses.self,
        policyNo,
      }))
      .filter(({ self }) => self.status === 'UNLOADED')
    const policiesToGet = policyQueue.slice(0, EXECUTION_GROUP_LIMIT)
    dispatch({
      type: CUSTOMER_POLICY_SELF_LOADING,
      payload: policiesToGet,
    })
    // eslint-disable-next-line no-await-in-loop
    const policies = await Promise.all(
      policiesToGet.map(({ self: { url } }) => callExperienceAPIv2({ path: decodeURI(url) }))
    ).then(data => data.filter(responseCameBackAllGood))

    batch(() => {
      const unsuccessfulResponses = policiesToGet.filter(
        policy =>
          !policies.some(data => data && data.businessData.policy.bancsPolicyNo === policy.policyNo)
      )
      if (unsuccessfulResponses.length > 0) {
        dispatch({
          type: CUSTOMER_POLICY_SELF_FAILED,
          payload: unsuccessfulResponses,
        })
      }
      if (policies.length > 0) {
        dispatch(handlePolicyUpdate(policies))
      }
    })
  } while (getQueueLength(policyQueue) > 0)

  dispatch(fetchAllPolicySupportingData())
}

/**
 * fetches related party's based on desired roles in arguments
 *  fetchBatchRelatedParty('beneficiaries')
 *  fetchBatchRelatedParty('policyOwners', 'payers')
 *
 *  Note: This can only be called in context after all policies are loaded
 */
export const fetchBatchRelatedParty =
  (...orders) =>
  async (dispatch, getState) => {
    let relatedPartyQueue = []
    do {
      const { customerRelationships, customerPolicies } = getState()

      const customerPoliciesList = Object.values(customerPolicies)
      const uniqueBancsCustomerNos = [
        ...new Set(customerPoliciesList.flatMap(policy => orders.flatMap(order => policy[order]))),
      ]

      relatedPartyQueue = Object.entries(
        pick(customerRelationships, uniqueBancsCustomerNos)
      ).filter(([, value]) => !value.hasRelatedParty && value.loadingState !== 'FAILED')

      const relationshipsToGet = relatedPartyQueue.slice(0, EXECUTION_GROUP_LIMIT)

      batch(() => {
        relationshipsToGet.forEach(([bancsCustomerNo]) =>
          dispatch({
            type: 'CUSTOMER_RELATEDPARTY_SELF_LOADING',
            payload: bancsCustomerNo,
          })
        )
      })

      // eslint-disable-next-line no-await-in-loop
      const relatedParties = await Promise.all(
        relationshipsToGet.map(([bancsCustomerNo, data]) =>
          callExperienceAPIv2({
            path: `/${PORTAL_API_PATH}/policy/${bancsCustomerNo}/related-party${fetchOds(data)}`,
          })
        )
      ).then(data =>
        /** This is required for beneficiaries edge case where 15 minutes after
         * adding a LV we get a junk response we have to handle */
        relationshipsToGet.map(([bancsCustomerNo], index) => ({
          bancsCustomerNo,
          ...data[index],
        }))
      )

      // eslint-disable-next-line no-loop-func
      batch(() => {
        relatedParties.forEach((data, index) => {
          dispatch(
            responseCameBackAllGood(data)
              ? {
                  type: 'CUSTOMER_RELATEDPARTY_SELF_SUCCESS',
                  payload: data,
                  odsConditionMet: true,
                  bancsCustomerNo: data.bancsCustomerNo,
                }
              : {
                  type: 'CUSTOMER_RELATEDPARTY_SELF_FAILED',
                  payload: relationshipsToGet[index][0],
                }
          )
        })
      })
    } while (relatedPartyQueue.length > 0)
  }

export const getPolicySummary = customerBancsNo => async dispatch => {
  dispatch({
    type: CUSTOMER_SUMMARY_LOADING,
  })

  if (!customerBancsNo) {
    // Arbitrary time out to show feedback to the user.
    setTimeout(
      () =>
        dispatch({
          type: CUSTOMER_SUMMARY_FAILED,
        }),
      200
    )
    return
  }

  const url = `/${PORTAL_API_PATH}/policies/${customerBancsNo}`
  const apiResponse = await callExperienceAPIv2({ path: url })
  if (responseCameBackAllGood(apiResponse)) {
    const {
      businessData: { policies = [] },
    } = apiResponse
    dispatch({
      type: SETUP_CUSTOMER_POLICY_STATUS_MAP,
      payload: policies,
    })

    dispatch({
      type: UPDATE_CUSTOMER_POLICY,
      payload: policies.map(item => ({
        policyNo: item.bancsPolicyNo,
        ...item,
      })),
    })

    const relationships = policies.flatMap(policy =>
      policy.lifeAssured.map(relationship => ({
        ...relationship,
        roleCode: 'LA',
        bancsPolicyNo: policy.bancsPolicyNo,
      }))
    )

    dispatch({
      type: UPDATE_RELATIONSHIP_FROM_POLICIES_SUMMARY,
      payload: relationships,
    })

    dispatch({
      type: CUSTOMER_SUMMARY_SUCCESS,
    })

    dispatch(fetchAllPolicies())
  } else {
    dispatch({
      type: CUSTOMER_SUMMARY_FAILED,
    })
  }
}
