import { Dispatch } from 'redux'
import moment from 'moment'
import {
  LOA_REQUEST_INIT,
  LOA_REQUEST_SUCCESS,
  LOA_REQUEST_FAILURE,
  LOA_REQUEST_RESET,
  LOA_REMOVE_SUCCESS,
} from '../types/letterOfAuthority'
import { createLOARequestPayload } from '../../utils/contactUtils'
import {
  callExperienceAPIv2,
  responseCameBackAllGood,
  PORTAL_API_PATH,
} from '../../utils/customerPortalExperienceAPIUtils'
import { MasterList } from '../../types/masterData'

export const handleLOACreate =
  (form: { [key: string]: string }, callback: (success: boolean) => void, masterList: MasterList) =>
  async (dispatch: Dispatch) => {
    const { bancsCustomerNo } = form
    dispatch({ type: LOA_REQUEST_INIT })
    const response = await callExperienceAPIv2({
      requestBody: createLOARequestPayload(form, masterList),
      method: 'POST',
      path: `/${PORTAL_API_PATH}/loa/create/${bancsCustomerNo}`,
    })
    if (responseCameBackAllGood(response)) {
      dispatch({ type: LOA_REQUEST_SUCCESS })
      callback(true)
    } else {
      dispatch({ type: LOA_REQUEST_FAILURE })
      callback(false)
    }
  }

export const fetchActiveAuthorities = (bancsCustomerNo: string) => async (dispatch: Dispatch) => {
  const response = await callExperienceAPIv2({
    path: `/${PORTAL_API_PATH}/loa/retrieve/${bancsCustomerNo}`,
  })
  if (responseCameBackAllGood(response)) {
    dispatch({ type: LOA_REQUEST_SUCCESS, payload: response })
  } else {
    dispatch({ type: LOA_REQUEST_FAILURE })
  }
}

export const removeAuthority =
  (sourceBancsCustomerNo: string, targetBancsCustomerNo: string) => async (dispatch: Dispatch) => {
    dispatch({ type: LOA_REQUEST_INIT })
    const removed = await callExperienceAPIv2({
      path: `/${PORTAL_API_PATH}/loa/update`,
      method: 'PATCH',
      requestBody: {
        action: 'UPDATE',
        sourceBancsCustomerNo,
        roleCode: 'LOA',
        targetBancsCustomerNo,
        effectiveStartDate: moment().format('YYYY-MM-DD'),
        effectiveEndDate: moment().format('YYYY-MM-DD'),
      },
    })
    if (!removed || removed?.status?.success === 'false') {
      dispatch({ type: LOA_REQUEST_FAILURE })
    } else {
      const response = await callExperienceAPIv2({
        path: `/${PORTAL_API_PATH}/loa/retrieve/${targetBancsCustomerNo}`,
      })
      if (responseCameBackAllGood(response)) {
        dispatch({ type: LOA_REQUEST_SUCCESS, payload: response })
        dispatch({ type: LOA_REMOVE_SUCCESS })
        window.scrollTo(0, 0)
      } else {
        dispatch({ type: LOA_REQUEST_FAILURE })
      }
    }
  }

export const resetLOA = () => ({ type: LOA_REQUEST_RESET })
