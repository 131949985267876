// @flow
import { Variables } from '@mlcl-digital/mlcl-design'
import { getColorVariation, getAlignmentVariation } from '../../contentCard.styles'

const { space, colours, mediaQueries } = Variables

const styles = {
  tile: (bgVariation: string, alignVariation: string, tileWidth: number) => ({
    color: colours.tertiarySix,
    padding: `${space(3, true)}`,
    marginTop: `${space(2, true)}`,
    display: 'flex',
    flexFlow: 'row wrap',
    alignContent: 'start',
    ...getColorVariation(bgVariation),
    ...getAlignmentVariation(alignVariation),
    '& h3': {
      ...getColorVariation(bgVariation),
      flexBasis: '100%',
    },
    [mediaQueries.md]: {
      flexBasis: `calc(50% - ${space(2, true)})`,
      marginRight: `${space(2, true)}`,
    },
    [mediaQueries.lg]: {
      flexBasis: `calc(${tileWidth}% - ${space(2, true)})`,
    },
  }),
  iconContainer: {
    flexBasis: '100%',
    '& svg': {
      color: colours.tertiarySix,
    },
    marginBottom: `${space(2, true)}`,
  },
  infoIcon: {
    color: colours.tertiarySix,
  },
  tooltipContainer: {
    display: 'inline-block',
    marginLeft: space(2),
  },
  tooltipContent: {
    width: '314px',
    textAlign: 'left',
  },
}

export default styles
