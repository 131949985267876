// @flow
/* eslint-disable max-len */
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../actions'

// component.
import HeadingComponent from '../../../atoms/Heading'
import LifeInsuredDetailsComponent from '../../../organisms/LifeInsuredDetails'
import WithLoader from '../../../molecules/WithLoader'

// styles.
import styles from './personalStatement.styles'
import { space, colours } from '../../../../styles'

// constants
import { CUSTOMER_PERSONAL_STATEMENT_METHOD_PAGE } from '../../../../constants/personalStatement'
import { NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION } from '../../../../constants/navigation'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../../utils/sitecoreUtils'
import history from '../../../../utils/browserHistory'
import PersonalStatementLayout from '../../../molecules/PersonalStatementLayout'
import { getUREAssignedParty, handleUREByOtherParty } from '../../../../utils/quoteUtils'
import SeriesCodeChangedAlert from '../../../molecules/SeriesCodeChangedAlert'
import { hasSeriesCodeExpired, isNotSeriesSpecified } from '../../../../utils/extendedQuoteUtils'
import { URE_BY_ADVISER } from '../../../../constants/application'

// styled components
const FormCaption = styled(HeadingComponent)(styles.caption)
const Message = styled('p')(styles.message)

type CustomerDetailsPropTypes = {
  fields: Object,
  actions: {
    changeNavigationType: Function,
    fetchQuoteCollection: Function,
    getAllProducts: Function,
    getMasterList: Function,
    resetUREData: Function,
    resetCreateQuote: Function,
  },
  quoteCollectionId: Object,
  isQuoteCollectionLoading: boolean,
  createQuote: Object,
  masterList: Object,
  // config object for all the constant
  config: Object,
  productRules: Object,
}

export class CustomerDetails extends Component<CustomerDetailsPropTypes> {
  componentDidMount() {
    const {
      actions: {
        changeNavigationType,
        getAllProducts,
        fetchQuoteCollection,
        updateQuoteCollection,
        getMasterList,
        resetUREData,
        resetCreateQuote,
      },
      quoteCollectionId,
      createQuote,
      masterList,
    } = this.props

    changeNavigationType(NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION)
    resetUREData()
    resetCreateQuote()
    getAllProducts(dispatch => {
      dispatch(
        fetchQuoteCollection(quoteCollectionId, (err, dataReceived) => {
          /**
           * callback to update the underwriting details in case there are multiple quotes having same enquiryId,
           * where one has URE complete and the other(active quote) has MY LINK.
           * This is done to enable the customer to retrieve the blocked application.
           */
          if (dataReceived && dataReceived.quotes.length > 1) {
            const { quotes, quoteIDToAction } = dataReceived
            const activeQuote = quotes.find(quote => quote.quoteId === quoteIDToAction)
            const activeQuoteIndex = quotes.findIndex(quote => quote.quoteId === quoteIDToAction)
            let targetedUnderwritingDetails = {}
            quotes.forEach(quote => {
              if (
                quote.quoteId !== activeQuote.quoteId &&
                quote.underwritingDetails.enquiryId === activeQuote.underwritingDetails.enquiryId &&
                !isEqual(quote.underwritingDetails, activeQuote.underwritingDetails) &&
                !activeQuote.underwritingDetails.status &&
                quote.underwritingDetails.status
              ) {
                targetedUnderwritingDetails = quote.underwritingDetails
              }
            })
            dataReceived.quotes[activeQuoteIndex].underwritingDetails = {
              ...(targetedUnderwritingDetails.enquiryId
                ? targetedUnderwritingDetails
                : activeQuote.underwritingDetails),
              underwritingMethod: activeQuote.underwritingDetails.underwritingMethod,
            }
            dataReceived.quotes[activeQuoteIndex].type = 'DRAFT_APPLN'
            updateQuoteCollection(dataReceived)
          }
        })
      )
    })
    // Get Master Data
    if (isEmpty(masterList.data)) getMasterList()

    // show app has beeen assigned to other party
    handleUREByOtherParty(createQuote)
  }

  componentWillReceiveProps(nextProps) {
    if (
      // eslint-disable-next-line react/destructuring-assignment
      !(getUREAssignedParty(this.props.createQuote) === URE_BY_ADVISER) &&
      getUREAssignedParty(nextProps.createQuote) === URE_BY_ADVISER
    ) {
      handleUREByOtherParty(nextProps.createQuote)
    }
  }

  // eslint-disable-next-line react/sort-comp
  hasPolicy() {
    const { createQuote } = this.props
    const { activeIndex, quotes } = createQuote
    const hasPolicy = get(quotes, `${activeIndex}.policyStructure`, []).length
    return hasPolicy
  }

  handleSubmit = () => {
    history.push(CUSTOMER_PERSONAL_STATEMENT_METHOD_PAGE)
  }

  render() {
    const { fields, isQuoteCollectionLoading, config, createQuote, productRules } = this.props
    const { lifeInsureHeading, lifeInsureSubHeading } = fields

    const { quotes, activeIndex, isFetchingData } = createQuote

    const quote = quotes[activeIndex]
    const products = productRules.data

    let changeInSeriesCode = false

    if (
      !isFetchingData &&
      !productRules.isFetching &&
      isNotSeriesSpecified(5, quote) &&
      hasSeriesCodeExpired(quote, products)
    ) {
      changeInSeriesCode = true
    }

    return (
      <WithLoader
        loaderProps={{ loaderContent: renderTextField(fields.SaveLoaderMessage) }}
        isLoading={isFetchingData}
        childrenBackgroundColor={colours.white}
        overlay
      >
        <PersonalStatementLayout fields={fields} config={config}>
          {!this.hasPolicy() || isQuoteCollectionLoading ? (
            <Loader spinnerSize={Number(space(10))} borderSize={Number(space(0.5))} />
          ) : (
            <Fragment>
              <FormCaption size={3}>{renderTextField(lifeInsureHeading)}</FormCaption>
              <Message>{renderTextField(lifeInsureSubHeading)}</Message>
              <LifeInsuredDetailsComponent
                isCustomer
                fields={reduceAuthorableFields(fields)}
                afterSubmission={this.handleSubmit}
              />
            </Fragment>
          )}
          <SeriesCodeChangedAlert fields={fields} isOpen={changeInSeriesCode} />
        </PersonalStatementLayout>
      </WithLoader>
    )
  }
}

export const mapStateToProps = ({
  myLink: { isQuoteCollectionLoading, quoteCollectionId },
  createQuote,
  masterList,
  config,
  productRules,
}) => ({
  isQuoteCollectionLoading,
  quoteCollectionId,
  createQuote,
  masterList,
  config,
  productRules,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails)
