import { createSelector } from 'reselect'

// selectors
import { getPolicyStructure, getAlterations } from './common.selectors'
// @ts-expect-error file not in typescript
import { getIsAnyPolicyAltered } from './createQuote'
// @ts-expect-error file not in typescript
import { getAltsProductRules } from './alterations'
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'

// constants
import {
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
  BENEFITS_SHOWN_AS_OPTIONS,
} from '../constants/benefit'
import {
  POLICY_BENEFIT_TISO,
  POLICY_BENEFIT_PREMIUM_WAIVER,
  POLICY_BENEFIT_SMB,
  BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  INSIDE_SUPER,
  PREMIUM_CALCULATOR_SUB_BENEFITS,
  PREMIUM_CALCULATOR_HIDE_OPTIONS,
  POLICY_OAI_BENEFIT_CODE,
  POLICY_PRODUCT_CODE_PPP_NON_SUPER,
  PREMIUM_CALCULATOR_BENEFITS_SORT_LIST,
  POLICY_BENEFIT_HEP,
  POLICY_BENEFIT_ADB,
} from '../constants/policies'
import {
  BENEFIT_ALTS_TYPE_REMOVE,
  SYSTEM_GENERATED_REASON,
  PORTFOLIO_ANIVERSARY_PERIOD,
} from '../constants/alterations'

// @ts-expect-error file not in typescript
import { getPolicyTypeLabel } from '../utils/policyUtils'
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
// @ts-expect-error file not in typescript
import { sortResultsWithOrderList } from '../utils/sortingUtils'
import {
  makeAltsProductsWaitingPeriods,
  getCoverPeriodFromModifiedOptions,
  getWaitingPeriodFromModifiedOptions,
  // @ts-expect-error file not in typescript
} from '../utils/alteration'
// @ts-expect-error file not in typescript
import { getLinkedSgboBenefits, getLinkedPolicyInstanceNo } from '../utils/altsQuoteUtils'
// @ts-expect-error file not in typescript
import { isSGBOBenefit, getOptimiserChildBenefitInPolicies } from '../utils/extendedQuoteUtils'

// types
import {
  CoverGroups,
  PremiumCalculatorQuoteFields,
  PeriodData,
} from '../types/components/PremiumCalculator'
import { Cover, ChildBenefitReference } from '../types/createQuote'
import { PoliciesAssessment } from '../types/alterations'

// utils
import {
  getSectionDetails,
  getOptionDetails,
  getExistingAndUpdatedPaymentFrequency,
} from '../utils/premiumCalculator'

export const getPremiumCalculateQuoteData = createSelector(
  [
    getPolicyStructure,
    getAlterations,
    getIsAnyPolicyAltered,
    getAltsProductRules,
    (state, fields: PremiumCalculatorQuoteFields) => fields,
  ],
  (policyStructure, alterations, isAnyPolicyAltered, altsProductRules, fields) => {
    const premiumCalculatorQuote: CoverGroups[] = []
    const changedPolicy = policyStructure.find(policy => policy.alteration?.calculatePolicyPrice)
    let linkedPolicyInstanceNo: string
    if (changedPolicy) {
      linkedPolicyInstanceNo = getLinkedPolicyInstanceNo(changedPolicy)
    }
    policyStructure.forEach(policy => {
      const {
        productClass,
        policyInstanceNo,
        policyNo,
        productId,
        bancsPolicyNo,
        paymentFrequency,
      } = policy
      policy.covers.forEach(cover => {
        // excluding benefits TISO and WOP shown as options
        if (!BENEFITS_SHOWN_AS_OPTIONS.includes(cover.type)) {
          const searchedCoverGroup = premiumCalculatorQuote.find(
            // getSectionHeading being used for getting name of a benefit for labels on
            // this page from sitecore based on benefit code
            coverGroup =>
              coverGroup.coverGroupName ===
              getSectionDetails(fields?.Cover, cover.type)?.headingText
          )
          const {
            type,
            benefitInstanceNo,
            benefitCommencementDate,
            parentBenefitReference,
            name,
            optimiserParentBenefitReference,
            coverAmount,
            premiumAmount,
            tpdDefinition,
            childBenefitReferences,
            premiumStyle,
            features,
            subBenefits,
            coverStyle,
            waitingPeriod,
            coverPeriod,
            benefitAssured,
          } = cover
          let totalPremiumAmount = Number(premiumAmount)
          // Used to display parent benefit for a benefit
          // for optimiser benefits optimiserParentBenefitReference is used
          const parentReference = optimiserParentBenefitReference || parentBenefitReference
          // benefit in the alteration object if exists
          const alteredBenefit = policy.alteration?.alteredBenefits?.find(
            benefit =>
              benefit.benefitCode === type &&
              String(benefit.benefitInstanceNo) === String(benefitInstanceNo)
          )

          // List of benefits that needs to be shown as option for the benefit
          const benefitAsOptionsList = []
          const childBenefitTISO = childBenefitReferences?.find(
            childBenefit => childBenefit.childType === POLICY_BENEFIT_TISO
          )

          let newPremiumAmount = 0
          // Note: TISO premium is added to newPremiumAmount in TISO code block
          // isSystemGenerated true is for those benefits in alterations data returned from
          // bancs which are not directly altered
          // isAltered tells benefit is altered and newGrossBenefitPremium value exists
          const isAltered =
            alteredBenefit &&
            alteredBenefit.newGrossBenefitPremium &&
            (!alteredBenefit.isSystemGenerated ||
              (alteredBenefit.isSystemGenerated &&
                alteredBenefit.systemGeneratedReason !== SYSTEM_GENERATED_REASON.NONE))
          if (isAltered) {
            newPremiumAmount = Number(alteredBenefit.newGrossBenefitPremium)
          }
          // If TISO child exists for the benefit, it will be shown as option of the benefit
          if (childBenefitTISO) {
            const benefitTISO = policy.covers.find(
              benefit =>
                benefit.type === POLICY_BENEFIT_TISO &&
                String(benefit.benefitInstanceNo) ===
                  String(childBenefitTISO.childBenefitInstanceNo)
            )
            totalPremiumAmount += Number(benefitTISO?.premiumAmount ?? 0)
            const alteredTISO = policy.alteration?.alteredBenefits?.find(
              benefit =>
                benefit.benefitCode === POLICY_BENEFIT_TISO &&
                String(benefit.benefitInstanceNo) ===
                  String(childBenefitTISO.childBenefitInstanceNo)
            )
            // if TISO is removed, then it is shown as unselected, otherwise selected
            const isSelected = alteredTISO
              ? !(alteredTISO.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE)
              : true
            // adding TISO details
            const featureDetails = getOptionDetails(
              fields?.Options,
              POLICY_BENEFIT_TISO,
              type,
              productId
            )
            benefitAsOptionsList.push({
              displayedFeatureName: featureDetails?.headingText,
              description: featureDetails?.description,
              featureName: childBenefitTISO.childType,
              isSelected,
              disbaled: false,
              benefitInstanceNo: childBenefitTISO.childBenefitInstanceNo,
              policyInstanceNo: childBenefitTISO.childPolicyReferenceNo,
            })
            if (!isSelected) {
              if (!isAltered) {
                // If LC is not altered but TISO is removed, LC premium should be
                // displayed in new premium field
                newPremiumAmount = Number(premiumAmount)
              }
            } else if (isAltered) {
              // If LC is altered, TISO premium should be added to new premium amount for LC
              newPremiumAmount += Number(benefitTISO?.premiumAmount ?? 0)
            }
          }

          // If waiver exists in the policy, it will be shown as option in eligible benefits
          const waiverBenefit: Cover | undefined = policy.covers.find(
            benefit => benefit.type === POLICY_BENEFIT_PREMIUM_WAIVER
          )
          // Check Waiver eligibility for the benefit
          const isWaiverEligble = !(
            type === POLICY_BENEFIT_SMB ||
            BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS.includes(type) ||
            INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(type) ||
            (type === POLICY_OAI_BENEFIT_CODE && productId === POLICY_PRODUCT_CODE_PPP_NON_SUPER)
          )
          if (isWaiverEligble && waiverBenefit) {
            const alteredWavier = policy.alteration?.alteredBenefits?.find(
              benefit => benefit.benefitCode === POLICY_BENEFIT_PREMIUM_WAIVER
            )
            // if waiver exists in policy and waiver is eligible for benefit,
            // it will be shown as option
            // if it is removed, it is shown unselected, otherwise selected
            const isSelected = alteredWavier
              ? !(alteredWavier.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE)
              : true
            // adding details of Waiver benefit
            const featureDetails = getOptionDetails(
              fields?.Options,
              POLICY_BENEFIT_PREMIUM_WAIVER,
              type,
              productId
            )
            benefitAsOptionsList.push({
              featureName: waiverBenefit.type,
              displayedFeatureName: featureDetails?.headingText,
              description: featureDetails?.description,
              isSelected,
              disabled: true,
              benefitInstanceNo: waiverBenefit.benefitInstanceNo,
              policyInstanceNo,
            })
          }

          // making sub-benefits that are shown as options and these can't be altered
          const subBenefitsAsOptionsList = subBenefits
            ?.filter(subBenefit =>
              PREMIUM_CALCULATOR_SUB_BENEFITS.includes(subBenefit.subBenefitCode)
            )
            ?.map(subBenefit => {
              const featureDetails = getOptionDetails(
                fields?.Options,
                subBenefit.subBenefitCode,
                type,
                productId
              )
              return {
                featureName: subBenefit.subBenefitCode,
                displayedFeatureName: featureDetails?.headingText,
                description: featureDetails?.description,
              }
            })

          // child benefits to display under benefit
          let childBenefitList: ChildBenefitReference[] = []
          if (childBenefitReferences) {
            childBenefitList = childBenefitReferences
              // TISO is not shown as child as it is being shown as options under parent benefit
              // Removing optimiser(child) benefit from optimiser(parent) benefit, will be displayed
              // under parent of optimiser benefits
              .filter(
                childBenefit =>
                  childBenefit.childType !== POLICY_BENEFIT_TISO &&
                  childBenefit.benefitNature !== BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
              )
            const optimiserChildBenefit: {
              policyInstanceNo: string
              type: string
              benefitInstanceNo: string
              optimiserParentBenefitReference: { benefitNature: string }
            } = getOptimiserChildBenefitInPolicies(cover, policyStructure, policy.policyInstanceNo)
            if (optimiserChildBenefit) {
              // adding optimiser(child) to parent(LC/CI) of optimiser
              // childBenefitList already contains optimiser(parent) in parent benefit of optimiser
              childBenefitList.push({
                childPolicyReferenceNo: optimiserChildBenefit.policyInstanceNo,
                childType: optimiserChildBenefit.type,
                childBenefitInstanceNo: optimiserChildBenefit.benefitInstanceNo,
                benefitNature: optimiserChildBenefit.optimiserParentBenefitReference.benefitNature,
              })
            }
          }

          const periods = {
            waitingPeriod: waitingPeriod?.value,
            waitingPeriodUnit: waitingPeriod?.unit,
            coverPeriod: coverPeriod?.value,
            coverPeriodUnit: coverPeriod?.unit,
          }

          const modifiedOptions = alteredBenefit?.modifiedOptions

          const featuresList = features
            ?.filter(
              feature =>
                feature.featureApplicable === 'Y' &&
                !PREMIUM_CALCULATOR_HIDE_OPTIONS.includes(feature.featureName)
            )
            ?.map(feature => {
              const featureDetails = getOptionDetails(
                fields?.Options,
                feature.featureName,
                type,
                productId,
                feature.duration
              )
              return {
                featureName: feature.featureName,
                duration: feature.duration,
                durationUnit: feature.durationUnit,
                displayedFeatureName: featureDetails?.headingText,
                description: featureDetails?.description,
                disabled: isSGBOBenefit(type) || featureDetails.readonly,
                isSelected: !modifiedOptions?.find(
                  item =>
                    item.optionAlterationType === 'removed' &&
                    item.feature?.featureName === feature.featureName
                ),
              }
            })

          const { benefitPeriodData: coverPeriodData, waitingPeriodData } =
            makeAltsProductsWaitingPeriods(altsProductRules, {
              type,
              periods,
              productId,
              featuresList,
            }) as { benefitPeriodData: PeriodData[]; waitingPeriodData: PeriodData[] }

          const benefitDetails = getSectionDetails(fields.Type, type, productId)

          // used for getting linked SGBO
          const childBenefits = childBenefitReferences?.map(
            ({
              benefitNature,
              childType: childBenefitType,
              childPolicyReferenceNo,
              childBenefitInstanceNo,
            }) => ({
              benefitNature,
              childBenefitType,
              childPolicyReferenceNo,
              childBenefitInstanceNo,
            })
          )

          const coverData = {
            type,
            benefitName: name,
            benefitType: benefitDetails?.headingText,
            benefitDescription: benefitDetails?.description,
            benefitInstanceNo,
            benefitCommencementDate,
            lifeInsured: benefitAssured
              .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
              .join(', '),
            ...(parentReference && {
              parentBenefitForDisplay: {
                name: getSectionDetails(fields?.Cover, parentReference.parentType)?.headingText,
                parentPolicyReferenceNo: parentReference.parentPolicyReferenceNo,
                parentType: parentReference.parentType,
                parentBenefitInstanceNo: parentReference.parentBenefitInstanceNo,
              },
            }),
            // parentBenefit and childBenefits are
            // used for showing error in case sum insured of parent is less than children combined
            ...(parentBenefitReference && {
              parentBenefit: {
                parentPolicyReferenceNo: parentBenefitReference.parentPolicyReferenceNo,
                parentType: parentBenefitReference.parentType,
                parentBenefitInstanceNo: parentBenefitReference.parentBenefitInstanceNo,
              },
            }),
            // childBenefits also used for showing child benefits for a benefit
            childBenefits: childBenefitList.map(childBenefit => ({
              ...childBenefit,
              name: getSectionDetails(fields?.Cover, childBenefit.childType)?.headingText,
            })),
            // in case of optimiser benefits, linked to text is changed to optimised to
            linkedBenefitLabel: optimiserParentBenefitReference
              ? fields?.OptimisedToBenefitText?.value
              : fields?.ConnectedToBenefitText?.value,
            // Will be fixed as part of RET-22090, need closure on decimal values in sumInsured
            sumInsuredFieldValue: parseInt(
              alteredBenefit?.newSumInsured ?? coverAmount.toString(),
              10
            ),
            existingSumInsured: parseInt(coverAmount.toString(), 10),
            premiumStyle,
            isSumInsuredDisabled: type === POLICY_BENEFIT_HEP || type === POLICY_BENEFIT_ADB,
            premiumAmount: dollarAmountWithCommasAndTwoDecimal(totalPremiumAmount),
            newPremiumAmount:
              newPremiumAmount && dollarAmountWithCommasAndTwoDecimal(newPremiumAmount),
            featuresList,
            benefitAsOptionsList,
            subBenefitsAsOptionsList,
            tpdDefinition,
            isNoneOptionsSelected: ![...(featuresList ?? []), ...benefitAsOptionsList].some(
              item => !item.disabled && item.isSelected
            ),
            coverStyle,
            waitingPeriod,
            coverPeriod,
            waitingPeriodData: waitingPeriodData.map(waitPeriod => {
              const selectedPeriod = ((modifiedOptions &&
                getWaitingPeriodFromModifiedOptions(modifiedOptions)) ||
                waitingPeriod) as PeriodData
              return {
                ...waitPeriod,
                isSelected:
                  waitPeriod.unit === selectedPeriod?.unit &&
                  waitPeriod.value === selectedPeriod?.value,
              }
            }),
            coverPeriodData: coverPeriodData.map(covPeriod => {
              const selectedPeriod = ((modifiedOptions &&
                getCoverPeriodFromModifiedOptions(modifiedOptions)) ||
                coverPeriod) as PeriodData
              return {
                ...covPeriod,
                isSelected:
                  covPeriod.unit === selectedPeriod?.unit &&
                  covPeriod.value === selectedPeriod?.value,
              }
            }),
            // true for benefit for which more details needs to be displayed e.g. IP and BE
            isTwoRowLayout: !!waitingPeriod && !isSGBOBenefit(type),
            linkedSGBOBenefits: getLinkedSgboBenefits(type, childBenefits, policyStructure),
            optimiserParentBenefitReference,
            isParentOptimiserBenefit: !!childBenefitReferences?.find(
              childBenefit => childBenefit.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
            ),
          }

          // disable change in other policies
          const isPolicyChangeDisabled =
            (isAnyPolicyAltered &&
              (alterations?.altsCalculateQuoteError ||
                (alterations?.sumInsuredErrors &&
                  Object.values(alterations?.sumInsuredErrors).some(errorVal => !!errorVal))) &&
              !(
                policy?.alteration?.calculatePolicyPrice ||
                String(linkedPolicyInstanceNo) === String(policyInstanceNo)
              )) ||
            false

          if (searchedCoverGroup) {
            const policyGroup = searchedCoverGroup.coverGroup.find(
              item => item.policy.policyNo === policyNo
            )
            if (policyGroup) {
              policyGroup.covers.push(coverData)
            } else {
              const policyType = getPolicyTypeLabel(productClass)
              const { existingPaymentFrequency, updatedPaymentFrequency } =
                getExistingAndUpdatedPaymentFrequency(
                  paymentFrequency,
                  policy.alteration?.newPremiumPayingFrequency
                )
              searchedCoverGroup.coverGroup.push({
                policy: {
                  policyNo,
                  policyType,
                  policyTypeChipVariant: policyType === INSIDE_SUPER ? 'infoLight' : 'important',
                  policyInstanceNo,
                  bancsPolicyNo: bancsPolicyNo as string,
                  isPolicyChangeDisabled,
                  paymentFrequency: existingPaymentFrequency,
                  updatedPaymentFrequency,
                },
                covers: [coverData],
              })
            }
          } else {
            const policyType = getPolicyTypeLabel(productClass)
            const { existingPaymentFrequency, updatedPaymentFrequency } =
              getExistingAndUpdatedPaymentFrequency(
                paymentFrequency,
                policy.alteration?.newPremiumPayingFrequency
              )
            premiumCalculatorQuote.push({
              type: cover.type,
              coverGroupName: getSectionDetails(fields?.Cover, cover.type)?.headingText,
              coverGroup: [
                {
                  policy: {
                    policyNo,
                    policyType,
                    policyTypeChipVariant: policyType === INSIDE_SUPER ? 'infoLight' : 'important',
                    policyInstanceNo,
                    bancsPolicyNo: bancsPolicyNo as string,
                    isPolicyChangeDisabled,
                    paymentFrequency: existingPaymentFrequency,
                    updatedPaymentFrequency,
                  },
                  covers: [coverData],
                },
              ],
            })
          }
        }
      })
    })
    return (
      premiumCalculatorQuote &&
      sortResultsWithOrderList(
        premiumCalculatorQuote,
        'type',
        PREMIUM_CALCULATOR_BENEFITS_SORT_LIST
      )
    )
  }
)

export const getAnniversaryBannerMessages = createSelector(
  [
    getPolicyStructure,
    getRulesForAlterationPolicies,
    (state, fields: PremiumCalculatorQuoteFields) => fields,
  ],
  (policyStructure, rules, fields) => {
    const bannerMessages: Array<{ policyNo: string; bannerMessage: string; period?: string }> = []
    policyStructure.forEach(polStructure => {
      const selectedPolicyInRules: PoliciesAssessment | undefined =
        rules?.businessData?.policies.find(
          policy => policy.bancsPolicyNo === polStructure.bancsPolicyNo
        )
      let bannerMessage = ''
      const policyAnniversaryPeriod =
        selectedPolicyInRules?.assesment?.decreaseIncrease_policyAnniversaryPeriod
      if (policyAnniversaryPeriod === PORTFOLIO_ANIVERSARY_PERIOD.PRE) {
        bannerMessage = fields?.PrePolicyAnniversaryBannerMessage?.value
      } else if (policyAnniversaryPeriod === PORTFOLIO_ANIVERSARY_PERIOD.WITHIN) {
        bannerMessage = fields?.WithinPolicyAnniversaryBannerMessage?.value
      }
      bannerMessage = bannerMessage.replace(
        '##',
        selectedPolicyInRules?.assesment?.rejectCPI_EscalationDate || ''
      )
      if (bannerMessage) {
        const isItemPresentWithSameMessage = bannerMessages.find(
          item => item.bannerMessage === bannerMessage
        )
        if (!isItemPresentWithSameMessage) {
          bannerMessages.push({
            policyNo: polStructure.policyNo,
            bannerMessage,
            period: policyAnniversaryPeriod,
          })
        } else {
          isItemPresentWithSameMessage.policyNo += `, ${polStructure.policyNo}`
        }
      }
    })
    return sortResultsWithOrderList(bannerMessages, 'period', [
      PORTFOLIO_ANIVERSARY_PERIOD.PRE,
      PORTFOLIO_ANIVERSARY_PERIOD.WITHIN,
    ]) as Array<{ policyNo: string; bannerMessage: string; period?: string }>
  }
)

// checks if there is frontend error or error from Bancs while performing alteration
export const isErrorOnAlteredQuote = createSelector(
  [getAlterations],
  alterations =>
    !!alterations?.altsCalculateQuoteError ||
    (alterations?.sumInsuredErrors &&
      Object.values(alterations?.sumInsuredErrors).some(errorVal => !!errorVal))
)
